document.querySelector("#toggle-nav").addEventListener("click", () => {
	document.querySelector("body").classList.toggle("nav-open");
});

if (document.querySelector(".ukds-carousel")) {
    var slider = tns({
        container: '.ukds-carousel',
        items: 1,
        slideBy: 'page',
        autoplay: true,
        mouseDrag: true,
        controls:false,
        autoplayButtonOutput:false,
        navPosition:'bottom'
      });
}

if (document.querySelector(".interactive-map")){
  const container = document.querySelector('#map-container');
  const rangeInput = document.getElementById('rangeInput');

  rangeInput.addEventListener('input', () => {
    const scrollValue = (container.scrollWidth - container.clientWidth) * (rangeInput.value / 100);
    container.scrollLeft = scrollValue;
  });
}

let timesOpening = false;

document.addEventListener('click', (event) => {
  const target = document.querySelector(".open-today-active");

  if (target) {
    const withinBoundaries = event.composedPath().includes(target.nextElementSibling)

    if (!withinBoundaries &! timesOpening) {
      document.querySelector(".open-today-active").classList.remove("open-today-active")
    } 
  }

})

document.querySelectorAll(".open-today").forEach(_sel => {
  _sel.addEventListener("click", () => {
    if (_sel.classList.contains("open-today-active")) {
      _sel.classList.remove("open-today-active");
      return true;
    } else {
      timesOpening = true;
      setTimeout(function() {
        timesOpening = false;
      },400);
    }
    document.querySelectorAll(".open-today-active").forEach(_active => {
      _active.classList.remove("open-today-active");
    });
    _sel.classList.toggle("open-today-active");
  })
});


window.addEventListener("load", () => {
  document.querySelectorAll("[data-lightbox]").forEach(function(_sel) {
    _farloUi.tools.lightbox.trigger(_sel);
  });
});
